import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  function handleChangeLanguage(event) {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
  }

  return (
    <select onChange={handleChangeLanguage} className="focus-visible:outline-none rounded-sm">
      <option value="fr">FR</option>
      <option value="en">ENG</option>
    </select>
  );
}

export default LanguageSelector;
