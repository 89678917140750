import { AiFillPhone } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer id="footer" >
    <div className="flex md:flex-row flex-col md:gap-y-0 gap-y-8  justify-center px-20 py-10">
      <div className="basis-1/4">
        <h4 className="font-bold text-xl mb-2">Contact</h4>
        <ul className="flex flex-col gap-y-1">
          <li className="flex gap-2 items-center"><AiFillPhone /> (+223) 70307930</li>
          <li className="flex gap-2 items-center"><MdEmail />contact@isysend.com</li>
          <li className="flex gap-2 items-center"><HiLocationMarker />Sébénikoro, Route Nationale N°5 Bamako, MALI </li>
        </ul>
      </div>
      </div>
      <div
          className="flex md:flex-row flex-col md:gap-0 gap-4  items-center justify-between bg-darkBlue-1 text-white md:px-20 px-4 py-4">
        <p>© {new Date().getFullYear()} IsySend. {t('footer.copyright')}</p>
        <p><Link to="http://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf" className="hover:text-sky-300" download>
          {t('legal-notice')}
        </Link> - <Link
            to="http://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf" className="hover:text-sky-300" download>
          {t('privacy-policy')}
            </Link></p>
      </div>
    </footer>
  );
};

export default Footer;
