import {useTranslation} from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <section className="hero-section bg-darkBlue-1 rounded-2xl my-10 flex lg:flex-row flex-col">
      <div className="md:p-14 md:pr-0 p-6 basis-9/12">
        <p className="text-green md:text-xl text-lg font-bold">IsySend</p>
        <h2 className="md:text-3xl text-2xl font-bold pb-6 pt-2 text-white">
          {t('best-product')}
        </h2>
        <p className="md:text-lg text-base md:pb-6 pb-3 text-white font-semibold underline">
        <a href="https://isypay.net" target="_blank" rel="noreferrer" >{t('learn-more-about-isypay')}</a>
        </p>
        <p className="md:text-lg text-base md:pb-6 pb-3 text-white font-semibold">
          {t('product-description')}
        </p>
      </div>
    </section>
  );
};

export default Hero;
