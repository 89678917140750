import {useTranslation} from "react-i18next";

const Apropos = () => {
    const { t } = useTranslation();
  return (
    <section className="a-propos mb-10">
      <h2 className="font-bold text-3xl mb-10 tarif-h2">{t('about-us')}</h2>
      <div className="flex items-center">
        <div className="basis-9/12">
          <h2 className="font-bold text-xl mb-3">
              {t('who-we-are')}
          </h2>
          <p>
              {t('what-we-do')}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Apropos;
