import Hero from "./Hero";
import Apropos from "./Apropos";
const Acceuil = () => {
  return (
    <>
      <Hero />
      <Apropos />
    </>
  );
};

export default Acceuil;
