import { Routes, Route, useLocation } from "react-router-dom";
import "tw-elements";
import "./styles/styles.css";
import Navbar from "./components/Navbar";
import Acceuil from "./components/Acceuil";
import Footer from "./components/Footer";
import { AnimatePresence } from "framer-motion";
// i18next imports
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// translation files
import enTranslations from './language/en.json';
import frTranslations from './language/fr.json';
function App() {

  // translation library
  i18n
  .use(initReactI18next) // Initialize the i18next library with React
  .init({
    lng: 'fr', // Set the default language
    resources: {
      en: { translation: enTranslations },
      fr: { translation: frTranslations },
    },
  });

  const location = useLocation();
  // console.log("location " + location )
  return (
    // <BrowserRouter>
      <div className="App">
        <Navbar />
        <div className="container md:w-11/12 w-11/12 mx-auto ">
        <AnimatePresence mode='wait'>
          <Routes key={location.pathname} location={location}>
            <Route path="/" element={<Acceuil />} />
          </Routes>
        </AnimatePresence>
        </div>
        <hr />
        <Footer />
      </div>
    // </BrowserRouter>
  );
}

export default App;
